import * as React from 'react'
import { useEffect, useState } from 'react'
import { CompanyRow } from '../../contracts/company-row'
import { OfferRow } from '../../contracts/offer-row'
import { CsvForm } from '../../components/csv-form/csv-form'
import { listCompanies, saveCompanies } from '../../services/company'
import { listOffers, saveOffers } from '../../services/offer'
import { instanceToPlain } from 'class-transformer'
import Loading from '../../components/loading/loading'

export default () => {
  const [isLoading, setIsLoading] = useState(true)
  const [companies, setCompanies] = useState<CompanyRow[]>()
  const [offers, setOffers] = useState<OfferRow[]>()

  useEffect(() => {
    const fetch = async () => {
      const [companies, offers] = await Promise.all([listCompanies(), listOffers()])
      setCompanies(companies)
      setOffers(offers)
      setIsLoading(false)
    }
    fetch()
  },[])

  const handleSave = async (newCompaniesState: CompanyRow[], newOffersState: OfferRow[]) => {
    setIsLoading(true)
    await Promise.all([
      saveCompanies(companies! as any, newCompaniesState.map(c => instanceToPlain(c) as CompanyRow) as any),
      saveOffers(offers!, newOffersState.map(o => instanceToPlain(o) as OfferRow))
    ])
    setIsLoading(false)
  }

  
  return (
    <main className='container'>
      {
        isLoading
          ? <div className='p-8 flex justify-center'><Loading label='Loading...' /></div>
          : <div className='max-w-7xl py-8 mx-auto'>
              <CsvForm companies={companies!} offers={offers!} onSave={handleSave} />
          </div>
      }
    </main>
  )
}