// import { IsOptional, IsString, IsUrl, IsUUID } from 'class-validator'
import { CompanyProperties } from './company'

export class CompanyRow implements CompanyProperties  {
  // @IsUUID()
  readonly id: string

  // @IsString()
  readonly name: string

  // @IsOptional()
  // @IsUrl()
  readonly memberLink: string | undefined

  // @IsString()
  readonly description: string

  constructor (
    id: string,
    name: string,
    memberLink: string,
    description: string
  ) {
    this.id = id
    this.name = name
    this.memberLink = memberLink
    this.description = description
  }
}