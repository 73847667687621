import { OfferProperties } from './offer'

/**
 * An offer, as it is received directly from Firestore.
 * You should really be using @see Offer directly.
 */
export class OfferRow implements OfferProperties  {
  constructor (
    public id: string,
    public title: string,
    public category: string,
    public expires: string,
    public lastUpdated: string,
    public availableThrough: string,
    public offeredBy: string,
    public terms: string,
    public link: string,
    public value: number,
    public type: string,
    public description: string
  ) {
  }
}