import React, { useState } from 'react'
import Papa from 'papaparse'
import { ClassConstructor } from '../../contracts/class-constructor'
import { validate, ValidationError } from 'class-validator'
import { XCircleIcon } from '@heroicons/react/24/solid'

export interface CsvTableProps<T> {
  headers: string[]
  title: string
  rowType: ClassConstructor<T>
  onPaste: (rows: T[]) => void
  data: T[]
}

export const CsvTable = <T extends object>({ title, headers, onPaste, rowType, data }: CsvTableProps<T>) => {
  const [importErrors, setImportErrors] = useState<ValidationError[]>([])
  
  const handleOnPaste = async (event: React.ClipboardEvent) => {
    if (!event.clipboardData) {
      return
    }
    setImportErrors([])
    const clipboard = event.clipboardData.getData('text').trim()
    try {
      const rows = await parseCsv(clipboard)
      onPaste(rows)
    } catch(e) {
      setImportErrors(e as ValidationError[])
    }
  }

  const parseCsv = async (clipboard: string): Promise<T[]> => {
    return new Promise<T[]>((resolve, reject) => {
      const rows: T[] = []
      Papa.parse<T>(clipboard, {
        header: true,
        delimiter: '\t',
        
        step: async results => {
          if (results.errors.length) {
            const [error] = results.errors
            if (error.code !== 'TooFewFields') {
              reject(results.errors)
            }
          }
          const item = new rowType() as any as T
          Object.assign(item, results.data)
          await validate(item).catch(reject)
          rows.push(item)
        },
        complete: () => resolve(rows)
      })
    })
  }

  return (
    <div className='flex flex-col space-y-4'>
      <h2 className='text-xl'>{title} <span className='text-sm'>({data.length} rows)</span></h2>

      <table className="min-w-full divide-y divide-gray-300" onPaste={handleOnPaste}>
        <thead className="bg-gray-50">
          <tr>
            {headers.map(header => <th scope='col' className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900' key={header}>{header}</th>)}
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200 bg-white">
          {data.map((row, i) => (
              <tr
                key={i}
              >
                {headers.map(header => <td key={header} className='whitespace-pre-wrap px-3 py-4 text-sm text-gray-500'>{(row as any)[header]}</td>)}
              </tr>
            ))}
        </tbody>
      </table>
      
      {importErrors.length > 0 && 
        <div className="rounded-md bg-red-50 p-4">
          <div className="flex">
            <div className="flex-shrink-0">
              <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
            </div>
            <div className="ml-3">
              <h3 className="text-sm font-medium text-red-800">There were errors with the pasted data</h3>
              <div className="mt-2 text-sm text-red-700">
                <pre>
                  {JSON.stringify(importErrors)}
                </pre>
              </div>
            </div>
          </div>
        </div>
      }
    </div>
  )
}