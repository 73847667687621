import * as React from 'react'
import { useState } from 'react'
import { ClassConstructor } from '../../contracts/class-constructor'
import { CompanyRow } from '../../contracts/company-row'
import { OfferRow } from '../../contracts/offer-row'
import { CsvTable } from '../csv-table/csv-table'
import { DocumentCheckIcon } from '@heroicons/react/24/outline'
import { classNames } from '../../utility/class-names'

export interface CsvFormProps {
  companies: CompanyRow[]
  offers: OfferRow[]
  onSave: (companies: CompanyRow[], offers: OfferRow[]) => void
}

export const CsvForm = (props: CsvFormProps) => {
  const [companies, setCompanies] = useState<CompanyRow[]>(props.companies)
  const [offers, setOffers] = useState<OfferRow[]>(props.offers)
  const [isDirty, setIsDirty] = useState(false)

  const handleCompaniesPasted = (rows: CompanyRow[]) => {
    setCompanies(rows)
    setIsDirty(true)
  }

  const handleOffersPasted = (rows: OfferRow[]) => {
    setOffers(rows)
    setIsDirty(true)
  }

  const handleSave = () => {
    props.onSave(companies, offers)
    setIsDirty(false)
  }
  
  return (
    <div className='space-x-6 space-y-6'>
      <p className='bg-blue-100 text-blue-700 border-blue-700 border-solid border p-3 rounded-md text-center box-border'>Paste sheets into tables from <a className='font-semibold' href='https://docs.google.com/spreadsheets/d/198kCynP2Px-EGfpHxpAcRaBL5f-D1tYoS2cXAolPSyw' target='_blank'>https://docs.google.com/spreadsheets/d/198kCynP2Px-EGfpHxpAcRaBL5f-D1tYoS2cXAolPSyw</a></p>
      <div>
        <button className={classNames('cursor-pointer inline-flex items-center justify-center rounded-md border border-transparent bg-primary-600 px-4 py-2 text-md font-medium text-white shadow-lg hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 sm:w-auto',
          'fixed bottom-2 right-4',
          'disabled:bg-gray-400 disabled:cursor-not-allowed')}
          color='primary'
          onClick={handleSave}
          disabled={!isDirty}
        >
          <DocumentCheckIcon className='mr-2 w-6' />
          Save
        </button>
      </div>
      <CsvTable
        title='Companies'
        headers={Object.keys(new (CompanyRow as ClassConstructor<{}>)())}
        rowType={CompanyRow}
        onPaste={handleCompaniesPasted}
        data={companies}
        />

      <CsvTable
        title='Offers'
        headers={Object.keys(new (OfferRow as ClassConstructor<{}>)())}
        rowType={OfferRow}
        onPaste={handleOffersPasted}
        data={offers}
        />
    </div>
  )
}